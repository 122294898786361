<template>
     <div class="ex-basic-1 pt-3 pb-5">
    <div class="container">
      <div class="row">
        <div class="col-xl-10 offset-xl-1">
          <div>
            <h5>
              Estamos a tu plena disposición en País Vasco para los siguientes casos:
            </h5>
          </div>
          <ul class="list-unstyled li-space-lg mb-5">
            <li class="d-flex">
              <i class="fas fa-square"></i>
              <div class="flex-grow-1">
                <strong>Reformas y reparaciones</strong> interior y exterior de
                vivienda
              </div>
            </li>
            <li class="d-flex">
              <i class="fas fa-square"></i>
              <div class="flex-grow-1">
                <strong>Pintura y solución</strong> de grietas en fachadas e
                interiores
              </div>
            </li>
            <li class="d-flex">
              <i class="fas fa-square"></i>
              <div class="flex-grow-1">
                <strong>Vaciado y limpieza</strong> de pisos, chalets, garajes o
                naves
              </div>
            </li>
            <li class="d-flex">
              <i class="fas fa-square"></i>
              <div class="flex-grow-1">
                <strong>Vaciado y desalojo</strong> de trastos en viviendas
                habitadas por personas con síndrome de diógenes
              </div>
            </li>
            <li class="d-flex">
              <i class="fas fa-square"></i>
              <div class="flex-grow-1">
                <strong>Limpieza de siniestros</strong> en viviendas (incendios,
                inundaciones, etc.)
              </div>
            </li>
            <li class="d-flex">
              <i class="fas fa-square"></i>
              <div class="flex-grow-1">
                <strong>Retirada de jardinería</strong> y otros restos vegetales
              </div>
            </li>
            <li class="d-flex">
              <i class="fas fa-square"></i>
              <div class="flex-grow-1">
                <strong>Recogida de electrodomésticos</strong>, alfombras, ropa,
                sillones, sofas viejos a domicilio
              </div>
            </li>
            <li class="d-flex">
              <i class="fas fa-square"></i>
              <div class="flex-grow-1">
                <strong>Recogida de maquinaria</strong> en desuso, chatarra,
                muebles inútiles
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>